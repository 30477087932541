<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h4 class="mb-2">Dati</h4>
    <v-row>
      <FormItem
        v-model="skill_type"
        rules="required"
        type="select"
        :values="skillTypes"
        name="Tipo Skill"
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem v-model="label" rules="required" name="Etichetta" :lg="3" />
    </v-row>  
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({ 
  getterType: 'virtualAgentsReasons/getCurrent',
  mutationType: 'virtualAgentsReasons/SET_CURRENT_FIELDS',
})

export default {
  name: 'ReasonForm',
  components: {
    FormItem,
    Form,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo Motivo',
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'label',
      'skill_type',
    ]),
    ...mapState('virtualAgentsReasons', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
    ...mapGetters('virtualAgentsPresets', ['skillTypes']),
  },
  async mounted() {
    await Promise.all([
      this.getSkillTypes(),
    ])
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('virtualAgentsReasons', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapMutations('virtualAgentsPresets', {
      resetSkillTypes: 'RESET_SKILL_TYPES',
    }),
    ...mapActions('virtualAgentsPresets', ['getSkillTypes']),
    ...mapActions('virtualAgentsReasons', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.resetSkillTypes()
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
