<template>
  <v-container fluid>
    <PresetsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco Agenti Virtuali"
      module="virtualAgentsPresets"
      :headers="headers"
      :actions="actions"
      sortby="created_at"
    >
      <template v-slot:item.skill_type="{ item }">
        <div
          v-for="skill_type in item.skill_type"
          :key="skill_type"
          :skill_type="skill_type"
        >
          {{ skillType(skill_type) }}
        </div>
      </template>
      <template v-slot:actions>
        <v-btn
          v-if="canUser('virtual_agents_presets', 'create')"
          color="green"
          class="mx-2"
          dark
          tile
          @click="openModalPreset"
        >
          Aggiungi Agente
        </v-btn>
        <v-btn
          v-if="canUser('virtual_agents_reasons', 'create')"
          color="blue"
          class="mx-2"
          dark
          tile
          @click="openModalReason"
        >
          Aggiungi Motivo
        </v-btn>
        <v-btn
          v-if="canUser('virtual_agents_services', 'create')"
          color="green"
          class="mx-2"
          dark
          tile
          @click="openModalService"
        >
          Aggiungi Servizio
        </v-btn>
      </template>
    </BaseTable>
    <v-dialog
      v-model="dialogPreset"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClosePreset"
    >
      <v-card>
        <v-card-title>Agente Virtuale</v-card-title>
        <v-card-text>
          <PresetForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmittedPreset"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogReason"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalCloseReason"
    >
      <v-card>
        <v-card-title>Motivo</v-card-title>
        <v-card-text>
          <ReasonForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmittedReason"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogService"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalCloseService"
    >
      <v-card>
        <v-card-title>Servizio</v-card-title>
        <v-card-text>
          <ServiceForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmittedService"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { authComputed } from '@state/helpers.js'
import PresetsSectionSearch from '@components/virtualagents/preset/PresetsSectionSearch.vue'
import PresetForm from '@components/virtualagents/preset/PresetForm.vue'
import ReasonForm from '@components/virtualagents/reason/ReasonForm.vue'
import ServiceForm from '@components/virtualagents/service/ServiceForm.vue'

export default {
  name: 'VirtualAgentPresetsSearch',
  page: {
    title: 'Impostazioni Agenti Virtuali',
  },
  components: {
    BaseTable,
    PresetForm,
    ReasonForm,
    ServiceForm,
    PresetsSectionSearch,
  },
  data() {
    return {
      dialogPreset: false,
      dialogReason: false,
      dialogService: false,
      editMode: false,
      headers: [
        {
          text: 'Tipo Skill',
          value: 'skill_type',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'ID Agente DF',
          value: 'df_agent_id',
        },
        {
          text: 'Data Creazione',
          value: 'created_at',
        },
        {
          text: 'Data Aggiornamento',
          value: 'updated_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () =>
            this.canUser('virtual_agents_presets', 'update'),
          handler: this.openModalPreset,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () =>
            this.canUser('virtual_agents_presets', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('virtualAgentsPresets', ['skillType']),
  },
  mounted() {
    this.setScopes([])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapActions('virtualAgentsPresets', ['removeItem']),
    ...mapMutations('virtualAgentsPresets', {
      setCurrent: 'SET_CURRENT',
      setScopes: 'SET_SCOPES',
      resetCurrent: 'RESET_CURRENT',
      resetFilters: 'RESET_FILTER_FIELDS',
    }),
    ...mapActions('virtualAgentsReasons', {
      getReasons: 'getDropdownList',
    }),
    ...mapActions('virtualAgentsServices', {
      getServices: 'getDropdownList',
    }),
    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione dell'agente virtuale ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },

    openModalPreset(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialogPreset = true
    },
    openModalReason(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialogReason = true
    },
    openModalService(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialogService = true
    },
    onSubmittedPreset() {
      this.modalClosePreset()
      this.$refs.table.refresh()
    },
    onSubmittedReason() {
      this.modalCloseReason()
    },
    onSubmittedService() {
      this.modalCloseService()
    },
    modalClosePreset() {
      this.dialogPreset = false
      this.editMode = false
      this.resetCurrent()
    },
    modalCloseReason() {
      this.getReasons()
      this.dialogReason = false
      this.editMode = false
      this.resetCurrent()
    },
    modalCloseService() {
      this.getServices()
      this.dialogService = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
