<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="name"
        type="text"
        name="Nome"
        dense
        :sm="6"
        :md="3"
        :lg="3"
        :xl="3"
      />

      <FormItem
        v-model="skill_type"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per skill`"
        :name="`Tipo Skill`"
        :type="`select`"
        :values="skillTypes"
      />

      <FormItem
        v-model="role"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per ruolo`"
        :name="`Ruolo`"
        :type="`select`"
        :values="roles"
      />

       <FormItem
        v-model="attitude"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per attitudine`"
        :name="`Attitudine`"
        :type="`select`"
        :values="attitudes"
      />

      <FormItem
        v-model="spending_range"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per range di spesa`"
        :name="`Range di spesa`"
        :type="`select`"
        :values="spendingRanges"
      />

      <FormItem
        v-model="loyalty"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per fedeltà`"
        :name="`Fedeltà`"
        :type="`select`"
        :values="loyalties"
      />

      <FormItem
        v-model="arrears"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per arretrati`"
        :name="`Arretrati`"
        :type="`select`"
        :values="arrearsValues"
      />

       <FormItem
        v-model="first_time"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per prima volta`"
        :name="`Prima volta`"
        :type="`select`"
        :values="firstTimeOptions"
      />

      <FormItem
        v-model="sentiment"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per sentimento`"
        :name="`Sentimento`"
        :type="`select`"
        :values="sentiments"
      />

      <FormItem
        v-model="approach"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per approccio`"
        :name="`Approccio`"
        :type="`select`"
        :values="approaches"
      />

      <FormItem
        v-model="reason"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per motivo`"
        :name="`Motivo`"
        :type="`select`"
        :values="reasons"
      />

      <FormItem
        v-model="service"
        :clearable="true"
        :cols="3"
        :dense="true"
        :hint="`Ricerca per servizio`"
        :name="`Servizio`"
        :type="`select`"
        :values="services"
      />

      <FormItem
        v-model="df_agent_id"
        type="text"
        name="ID Agente DF"
        dense
        :md="3"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue' 
import { createHelpers } from 'vuex-map-fields' 
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'virtualAgentsPresets/getFiltersFields',
  mutationType: 'virtualAgentsPresets/SET_FILTER_FIELDS',
})

export default {
  name:'PresetsSectionSearch',
  components: { FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'name',
      'skill_type',
      'role',
      'attitude',
      'spending_range',
      'loyalty',
      'arrears',
      'first_time',
      'sentiment',
      'approach',
      'reason',
      'service',
      'df_agent_id'
      ]),
    ...mapState('virtualAgentsReasons', {
      reasons: 'list',
    }),
    ...mapState('virtualAgentsServices', {
      services: 'list',
    }),
    ...mapState('virtualAgentsPresets', ['filters', 'types']),
    ...mapGetters('virtualAgentsPresets', ['skillTypes', 'sentiments', 'roles', 'attitudes', 'spendingRanges', 'loyalties', 'firstTimeOptions', 'approaches']),
    ...mapGetters('virtualAgentsPresets', {arrearsValues: "arrears"}),
  },
  async mounted() {
    await Promise.all([
      this.getSkillTypes(),
      this.getRoles(),
      this.getAttitudes(),
      this.getSentiments(),
      this.getSpendingRanges(),
      this.getLoyalties(),
      this.getArrears(),
      this.getFirstTimeOptions(),
      this.getApproaches(),
      this.getReasons(),
      this.getServices(),
    ])
  },
  beforeDestroy() {
    this.reset()
    this.resetSkillTypes()
    this.resetRoles()
    this.resetAttitudes()
    this.resetSpendingRanges()
    this.resetLoyalties()
    this.resetArrears()
    this.resetApproaches()
    this.resetFirstTimeOptions()
    this.resetReasons()
    this.resetSentiments()
    this.resetServices()
  },
  methods: {
    ...mapMutations('virtualAgentsPresets', {
      reset: 'RESET_FILTER_FIELDS',
      resetSkillTypes: 'RESET_SKILL_TYPES',
      resetRoles: 'RESET_ROLES',
      resetAttitudes: 'RESET_ATTITUDES',
      resetSpendingRanges: 'RESET_SPENDING_RANGES',
      resetLoyalties: 'RESET_LOYALTIES',
      resetArrears: 'RESET_ARREARS',
      resetFirstTimeOptions: 'RESET_FIRST_TIME_OPTIONS',
      resetSentiments: 'RESET_SENTIMENTS',
      resetApproaches: 'RESET_APPROACHES',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetReasons: 'RESET_LIST',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetServices: 'RESET_LIST',
    }),
    ...mapActions('virtualAgentsPresets', ['getSkillTypes', 'getSentiments', 'getRoles', 'getAttitudes', 'getSpendingRanges', 'getLoyalties', 'getArrears', 'getFirstTimeOptions', 'getApproaches']),
    ...mapActions('virtualAgentsReasons', {
      getReasons: 'getDropdownList',
    }),
    ...mapActions('virtualAgentsServices', {
      getServices: 'getDropdownList',
    }),
    onSubmit() { 
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
