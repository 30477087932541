<template>
  <Form
    :hide-reset="edit"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h4 class="mb-2">Dati</h4>
    <v-row>
      <FormItem v-model="name" rules="required" name="Nome" :lg="3" />
      <FormItem
        v-model="skill_type"
        rules="required"
        type="select"
        :values="skillTypes"
        name="Tipo Skill"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="role"
        rules="required"
        type="select"
        :values="roles"
        name="Ruolo"
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>  
    <v-row>
      <FormItem
        v-model="attitude"
        rules="required"
        type="select"
        :values="attitudes"
        name="Attitudine"
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="spending_range"
        type="select"
        :values="spendingRanges"
        name="Range di spesa"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="loyalty"
        type="select"
        :values="loyalties"
        name="Fedeltà"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="arrears"
        rules="required"
        type="select"
        :values="arrearsValues"
        name="Arretrati"
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="first_time"
        rules="required"
        type="select"
        :values="firstTimeOptions"
        name="Prima volta"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="sentiment"
        rules="required"
        type="select"
        :values="sentiments"
        name="Sentimento"
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
    <v-row>
      <FormItem
        v-model="approach"
        rules="required"
        type="select"
        :values="approaches"
        name="Approccio"
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="reason"
        rules="required"
        type="select"
        :values="reasons"
        name="Motivo"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="service"
        rules="required"
        type="select"
        :values="services"
        name="Servizio"
        multiple
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
    <v-row>
      <FormItem 
      v-model="df_agent_id" 
      rules="required" 
      name="DF Agent ID"       
      :md="10"
      :lg="8"
      :xl="8" 
      />
    </v-row>
    <v-row>
    <FormItem 
      v-model="fidelity" 
      rules="required" 
      name="Fedeltà"
      type="number"       
      :md="4"
      :lg="3"
      :xl="3" 
      />
    <FormItem 
      v-model="growth" 
      rules="required" 
      name="Crescita"
      type="number"       
      :md="4"
      :lg="3"
      :xl="3" 
      />
      <FormItem
        v-model="broker_type"
        type="select"
        :values="[
          { value: 'type1', text: 'type1' },
          { value: 'type2', text: 'type2' },
        ]"
        name="Tipo Broker"
        :clearable="true"
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
    <v-row>
      <v-col>
        <vue-json-editor
            v-model="params_fields"
            :show-btns="false"
            :expanded-on-start="true"
            lang="it"
          ></vue-json-editor>
      </v-col>
    </v-row> 
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import vueJsonEditor from 'vue-json-editor'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({ 
  getterType: 'virtualAgentsPresets/getCurrent',
  mutationType: 'virtualAgentsPresets/SET_CURRENT_FIELDS',
})

export default {
  name: 'PresetForm',
  components: {
    FormItem,
    Form,
    vueJsonEditor,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo Agente Virtuale',
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'name',
      'skill_type',
      'role',
      'attitude',
      'spending_range',
      'loyalty',
      'arrears',
      'first_time',
      'sentiment',
      'approach',
      'reason',
      'service',
      'fidelity',
      'growth',
      'broker_type',
      'params_fields',
      'df_agent_id',
    ]),
    ...mapState('virtualAgentsPresets', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
    ...mapState('virtualAgentsReasons', {
      reasons: 'list',
    }),
    ...mapState('virtualAgentsServices', {
      services: 'list',
    }),
    ...mapGetters('virtualAgentsPresets', ['skillTypes', 'sentiments', 'roles', 'attitudes', 'spendingRanges', 'loyalties', 'firstTimeOptions', 'approaches']),
    ...mapGetters('virtualAgentsPresets', {arrearsValues: "arrears"}),
  },
  async mounted() {
    await Promise.all([
      this.getSkillTypes(),
      this.getRoles(),
      this.getAttitudes(),
      this.getSentiments(),
      this.getSpendingRanges(),
      this.getLoyalties(),
      this.getArrears(),
      this.getFirstTimeOptions(),
      this.getApproaches(),
      this.getReasons(),
      this.getServices(),
    ])
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('virtualAgentsPresets', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapMutations('virtualAgentsPresets', {
      reset: 'RESET_FILTER_FIELDS',
      resetSkillTypes: 'RESET_SKILL_TYPES',
      resetRoles: 'RESET_ROLES',
      resetAttitudes: 'RESET_ATTITUDES',
      resetSpendingRanges: 'RESET_SPENDING_RANGES',
      resetLoyalties: 'RESET_LOYALTIES',
      resetArrears: 'RESET_ARREARS',
      resetFirstTimeOptions: 'RESET_FIRST_TIME_OPTIONS',
      resetSentiments: 'RESET_SENTIMENTS',
      resetApproaches: 'RESET_APPROACHES',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetReasons: 'RESET_LIST',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetServices: 'RESET_LIST',
    }),
    ...mapActions('virtualAgentsPresets', ['create', 'update', 'getSkillTypes', 'getSentiments', 'getRoles', 'getAttitudes', 'getSpendingRanges', 'getLoyalties', 'getArrears', 'getFirstTimeOptions', 'getApproaches']),
    ...mapActions('virtualAgentsReasons', {
      getReasons: 'getDropdownList',
    }),
    ...mapActions('virtualAgentsServices', {
      getServices: 'getDropdownList',
    }),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.resetSkillTypes()
      this.resetRoles()
      this.resetAttitudes()
      this.resetSpendingRanges()
      this.resetLoyalties()
      this.resetArrears()
      this.resetApproaches()
      this.resetFirstTimeOptions()
      this.resetReasons()
      this.resetSentiments()
      this.resetServices()
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
